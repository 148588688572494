import Vue from 'vue'
import axios from 'axios'
import httpConfig from './httpConfig'

export default class HttpService {
  // Will be used by this service for making API calls
  axiosIns = null

  httpConfig = { ...httpConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, httpOverrideConfig) {
    this.axiosIns = axiosIns
    this.axiosIns = axios.create({
      baseURL: 'https://api.loxcloud.com',
    })
    this.httpConfig = { ...this.httpConfig, ...httpOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.httpConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(null, error => {
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        // console.log('http 401, redirect to login')
        window.location.href = '/login'
        Vue.$router.push({ name: 'auth-login' })
      }
      return Promise.reject(error)
    })
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.httpConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.httpConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.httpConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.httpConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.httpConfig.loginEndpoint, ...args)
  }

  getMe(...args) {
    return this.axiosIns.get(this.httpConfig.getMeEndpoint, ...args)
  }

  userEmailVerify(...args) {
    return this.axiosIns.post(this.httpConfig.emailVerifyEndpoint, ...args)
  }

  passwordChange(...args) {
    return this.axiosIns.post(this.httpConfig.passwordChangeEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.httpConfig.registerEndpoint, ...args)
  }

  getShadowXPlans(...args) {
    return this.axiosIns.get(this.httpConfig.shadowxPlansEndpoint, ...args)
  }

  getShadowXOrders(...args) {
    return this.axiosIns.get(this.httpConfig.shadowxOrdersEndpoint, ...args)
  }

  getMailXPlans(...args) {
    return this.axiosIns.get(this.httpConfig.mailxPlansEndpoint, ...args)
  }

  getMailXOrders(...args) {
    return this.axiosIns.get(this.httpConfig.mailxOrdersEndpoint, ...args)
  }

  shadowsNewOrder(...args) {
    return this.axiosIns.post('/user/shadowx', ...args)
  }

  getShadowxPlanInfo(id) {
    return this.axiosIns.get(`/shadowx/plans/${id}`)
  }

  getShadowxOrderInfo(id) {
    return this.axiosIns.get(`/shadowx/orders/${id}`)
  }

  getShadowxOrderRenew(id, ...args) {
    return this.axiosIns.post(`/shadowx/orders/${id}/renew`, ...args)
  }

  getPayMethods() {
    return this.axiosIns.get('/pay/methods')
  }

  newAlipayOrder(...args) {
    return this.axiosIns.post('/user/pay/alipay_web', ...args)
  }

  newPay(path, ...args) {
    return this.axiosIns.post(path, ...args)
  }

  redeemCode(...args) {
    return this.axiosIns.post('/user/pay/code', ...args)
  }

  getPayOrders(...args) {
    return this.axiosIns.get('/user/pay/orders', ...args)
  }

  // mailx
  getMailXOrderInfo(id) {
    return this.axiosIns.get(`/mailx/orders/${id}`)
  }

  putMailXOrderUserName(id, ...args) {
    return this.axiosIns.put(`/mailx/orders/${id}/info`, ...args)
  }

  putMailXOrderPassword(id, ...args) {
    return this.axiosIns.put(`/mailx/orders/${id}/password`, ...args)
  }

  // user
  // password_change
  userPasswordChange(id, ...args) {
    return this.axiosIns.post('/user/password_change', ...args)
  }

  newTicket(...args) {
    return this.axiosIns.post('/user/tickets', ...args)
  }

  // stat
  getUserTrafficStat(...args) {
    return this.axiosIns.get('/user/traffic_stat', ...args)
  }

  getBlogPost(...args) {
    return this.axiosIns.get('/blog/posts', ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.httpConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
