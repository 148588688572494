import { format } from 'date-fns'
import * as moment from 'moment'

export function formatDate(date) {
  const d = new Date(date)
  return format(d, 'yyyy-MM-dd')
}

export function formatTime(date) {
  return date
}

export function convertTimestamp(timestamp) {
  if (timestamp < 1000000) {
    return '-'
  }
  // const d = new Date(timestamp * 1000) // Convert the passed timestamp to milliseconds
  const dateTimeString = moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')
  return dateTimeString
}
