export default {
  // Endpoints
  shadowxPlansEndpoint: '/shadowx/plans',
  mailxPlansEndpoint: '/mailx/plans',
  shadowxOrdersEndpoint: '/user/shadowx',
  mailxOrdersEndpoint: '/user/mailx',
  getMeEndpoint: '/user',
  emailVerifyEndpoint: '/user/email_verify',
  passwordChangeEndpoint: '/user/password_change',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
